
@font-face {
    font-family: 'Prospec';
    src: url("../static/fonts/Prospec.otf");
}

@font-face {
    font-family: 'ProspecLight';
    src: url("../static/fonts/ProspecLight.otf");
}

@font-face {
    font-family: 'CharlesElegant';
    src: url("../static/fonts/Morality.otf");
}

*
{
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body
{
    background-color:#c0c8cf;
    
}

.webgl
{
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

@import url('./Css/Preload/preloading.css');
@import url('./Css/Section1/contactbar.css');
@import url('./Css/Section1/section1.css');
@import url('./Css/Section2/section2.css');
@import url('./Css/Section3/section3.css');
@import url('./Css/Section4/section4.css')