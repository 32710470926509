.section1.work1.projects
{
    display: flex;
    flex-direction:column;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    font-family: 'Prospec';
    /* color: rgb(139, 229, 217); */
    color: rgb(0, 0, 0);
    font-size: 4vmin;
    padding-left: 5%;
    padding-right: 5%;
    padding-top:10%;
}

.section.work
{
    display: flex;
    flex-direction:column;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100vh;
    font-family: 'Prospec';
    /* color: rgb(139, 229, 217); */
    color: rgb(0, 0, 0);
    font-size: 4vmin;
    padding-left: 5%;
    padding-right: 5%;
    padding-top:5%;
}


.myProjectText
{
    font-family:  'Prospec' ;
    color:transparent;
    -moz-text-stroke:2px black;
    -webkit-text-stroke:2px black;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
}

.textProjectsDiv
{
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: flex-end;
    /* background-color:#1A2; */
    padding-top:5%;
}
/*---------------*/

.singleline
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}

.textProjectsTest
{
    font-family: 'CharlesElegant';
    text-transform: lowercase;
    font-size:2vmin;
    margin: 0.2rem 0;
    letter-spacing:2.5px;
    transition:0.2s;
    color:#000;
}

.colorBox
{
    height:25px;
    width:0px;
    margin-left:10px;
    /* background: linear-gradient(45deg, red, blue); */
    background: linear-gradient(90deg, #ee2c7a 0%, #fc7566  100%);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition:0.2s;
}

.singleline:hover > .colorBox
{    width:100px;
     transition:0.15s;
     animation-timing-function: cubic-bezier(.17,1.34,.9,.59);

}
/*---------------*/

.textProjects
{
    font-family: 'CharlesElegant';
    text-transform: lowercase;
    font-size:2vmin;
    margin: 0.2rem 0;
    letter-spacing:2.5px;
    transition:0.2s;
}

.textProjectsTest:hover > .spanProjectGradientText 
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #000000;
    -moz-text-stroke:0.5px #000000;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.textProjectsTest:hover > .pointProjects
{
    color:black;
    -webkit-text-stroke:2px black;
    transition:0.15s;
}

.textProjectsTest:hover > .spanProjectGradientTextPurple
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #000000;
    -moz-text-stroke:0.5px #000000;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}


.textProjectsTest::first-letter
{
    text-transform: uppercase;
}

.spanProjectGradientText
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #ee2c7a;
    -moz-text-stroke:0.5px rgb(238, 44, 122);
    background-image:url(../../../static/assets/pinkBackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.pointProjects
{
    font-size: 3vmin;
    color:transparent;
    /* background-image: linear-gradient(0deg, rgb(5, 37, 222) 0%, rgb(252, 117, 102) 56%, rgba(254, 193, 149, 1) 100%); */
    background-image: radial-gradient(circle at center, rgb(238, 44, 122) 0%, #fc7566 56%, rgb(251, 155, 86) 80%);
    -webkit-background-clip: text;
    transition:0.15s;
}


.spanProjectGradientTextPurple
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px RGBA(145,42,151,1);
    -moz-text-stroke:0.5px RGBA(145,42,151,1);
    background-image:url(../../../static/assets/purpleBackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}


/*-------------------------------------*/
@media screen and (max-width: 1400px) 
{
    .section.work
    {
        font-size: 4vmin;
    }
}

@media screen and (max-width: 800px) 
{
    .section.work
    {
        align-items: center;
        justify-content: flex-start;
        font-size: 8vmin;
    }
    .textProjectsDiv
    {
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: flex-start;
        padding-top:10%;
    }
    .textProjectsTest
    {
        font-family: 'CharlesElegant';
        text-transform: lowercase;
        font-size:3.4vmin;
        margin: 0.5rem 0;
        letter-spacing:2.5px;
        transition:0.2s;
        color:#000;
    }
    .spanProjectGradientText
    {
        font-size: 2.9vmin;
    }
    .spanProjectGradientTextPurple
    {
        font-size: 2.5vmin;
    }
    .pointProjects
    {
        font-size: 5vmin;
    }
}



/*--------------------------------chrome only css*/
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    .myProjectText
    {
        text-shadow: 0em 0em 0.8em rgba(0, 0, 0, 0.558);
    }
  }
