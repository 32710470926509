.contactLinkDiv
{
    position:fixed;
    left:0%;
    justify-content: center;
    align-items: center;
    height:100vh;
    display:flex;
    z-index:1;
}


nav ul
{
    list-style: none;
    font-size: 1.5vmin;
}
nav ul li
{
    padding:0.1rem;
    margin: 2rem 0.7rem;
    writing-mode: vertical-rl;
}

nav ul li a
{
    color:black;
    font-family: 'Prospec';
    font-size: 1.6vmin;
    text-shadow: 0em 0em 0.1em rgba(0, 0, 0, 0.587);
}

/* h4
{
   background-image: linear-gradient(90deg, rgba(94, 114, 235, 1) 0%, rgba(255, 145, 144, 1) 56%, rgba(254, 193, 149, 1) 100%);
    -webkit-background-clip: text;
} */

nav ul li a:hover
{
    color:#ee2c7a;
    text-shadow: 0em 0em 0.1em #ee2c7a;
    /* animation:double 1s cubic-bezier(0.76, 0, 0.3, 1)  alternate; */
}



.iconify.nav
{
    transform: rotate(90deg);
    padding-bottom: 5px;
}

/* -------------------------------------------- */

@media screen and (max-width:800px)
{
    .contactLinkDiv
        {
            position:fixed;
            z-index: 1;
            display:flex;
            align-items: flex-start;
            /* background-color: #1A2; */
            top:0;
            width:100vw;
            height:10vh;
        }
        nav ul
        {
            list-style: none;
            background: rgba(207, 207, 207, 0.232);
            backdrop-filter: blur( 5px );
            -webkit-backdrop-filter: blur( 5px );
            width:100vw;
            display:flex;
            justify-content:space-between;
        }
        nav ul li
        {
            display:inline-block;
            padding:0.1rem;
            margin: 0.7rem 0.7rem;
            writing-mode:horizontal-tb;
        }
        
        nav ul li a
        {
            color:#000;
            font-size: 3vmin;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .contact_me_button
        {
            display:none;
        }
        .iconify.nav
        {
            transform: rotate(0);
            padding-bottom: 8px;
        }
}