.singlelineMe
{
    /* background-color: #2A3; */
    width:34rem;
    padding-top:3%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.stayInTouchDiv
{
    background-color:#FFF;
    display: flex;
    flex-direction: column;
    margin-top:5%;
    padding:2rem;
    width:calc(34rem - 2rem - 2rem);
    border-radius: 30px;
    background: rgba( 255, 255, 255, 0.15 );
    backdrop-filter: blur( 14.5px );
    -webkit-backdrop-filter: blur( 14.5px );
    -moz-filter: blur(14.5px);
    -o-filter: blur(14.5px);
}

.meProjectsTest
{
    font-family: 'CharlesElegant';
    text-transform: lowercase;
    font-size:2vmin;
    margin: 0.2rem 0;
    letter-spacing:2.5px;
    transition:0.2s;
    color:#000;
    /* background-color: #2AB; */
    margin-top:1.5rem;
    text-align: justify;
}

.meProjectsTest::first-letter
{
    text-transform: uppercase;
}

.spanProjectGradientTextMe
{
    color:transparent;
    font-size: 2vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #ee2c7a;
    -moz-text-stroke:0.5px rgb(238, 44, 122);
    background-image:url(../../../static/assets/pinkBackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.2s;
}

.uperLink
{
    color:black;
    font-size: 1.5vmin;
    display:flex;
    padding: 0.5rem 0 0.5rem 0;
    transition:0.3s;
}

.uperLink:hover
{
    color:#ee2c7a;
    transition:0.2s;
    text-shadow: 0em 0em 0.1em #ee2c7a;
}

.stayInTocuhLink
{
    margin-left:5%;
    font-family: 'CharlesElegant';
    text-transform: lowercase;
}


@media screen and (min-width:1060px) and (max-width:1400px)
{
    .singlelineMe
    {
        width:28rem;
        /* background-color: #2A3; */
    }
    .stayInTouchDiv
    {
        width: calc(28rem - 4rem)
    }
}

@media screen and (max-width:800px)
{
    .singlelineMe
    {
        padding-top:5vh;
        display: flex;
        align-items: flex-end;
        justify-content:center;
        flex-direction: column;
        width:80vw;
        
    }
    .stayInTouchDiv
    {
        background-color:#FFF;
        display: flex;
        flex-direction: column;
        margin-top:5%;
        padding:0.5rem 0rem 0.5rem 0.7rem;
        width:80vw ;
        border-radius: 20px;
        background: rgba( 255, 255, 255, 0.15 );
        backdrop-filter: blur( 14.5px );
        -webkit-backdrop-filter: blur( 14.5px );
        -moz-filter: blur(14.5px);
        -o-filter: blur(14.5px);
    }
    .meProjectsTest
    {
        font-size: 3.5vmin;
    }
    .uperLink
    {
        color:black;
        font-size: 2.5vmin;

    }
}
