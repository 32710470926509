.presentation
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Prospec';
    /* color: rgb(139, 229, 217); */
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    font-size: 6vmin;
    justify-content: center;
    padding-right: 35%;
    padding-left: 5%;
    /* background-color:#2A3; */
}

.mainTitle
{
    position:relative;
    display: flex;
    flex-direction: column;
    padding:1rem;
    height:15vh;
    justify-content:space-between;
}

.alainTaiText 
{
    text-shadow: 0em 0em 10px rgba(0, 0, 0, 0.6);
}


.creativeDevText
{
    font-family:  'ProspecLight' ;
    padding-top:2rem;
    color:transparent;
    -moz-text-stroke:2px black;
    -webkit-text-stroke:2px black;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
}


@keyframes back 
{
    0%
    {
        background-position: -2000px 0;
    }
    100%
    {
        background-position: 0 0;
    }

}

.drapeau
{
    width:18vmin;
}

.scrollToNavigate{
    position:absolute;
    display: flex;
    width: 15em;
    justify-content: center;
    font-size: 1.2rem;
    bottom:1%; 
    left: calc(50% - 7.5em);
    animation:doubleClickMessage 2s cubic-bezier(0.76, 0, 0.3, 1)  infinite alternate;
}


@keyframes doubleClickMessage
{
    from 
    {
        opacity:1;
        transform:scale(1);
     }
     to 
   {
        opacity:0;
        transform: scale(1.1)
   }
}



.scrollView
{
    position:absolute;
    display: flex;
    justify-content: center;
    height:9rem;
    width: 3rem;
    bottom:0;
    left: calc(50% - 1.5rem);
    animation: scrollSvgLoop 2s cubic-bezier(0.76, 0, 0.3, 1)  infinite alternate;
}

@keyframes scrollSvgLoop 
{
    from 
    {
        transform:scale(0.4);
     }
     to 
   {
       transform:scale(0.5)
   }
}

.contact_me_button
{

    display:flex;
    padding-top:1rem;
}


.contactMe_Text
{
    color:black;
    font-size: 1.6vmin;
    padding:1rem;
    border:1.5px solid #000;
    border-radius: 40px;
    text-shadow: 0em 0em 0.1em rgba(0, 0, 0, 0.483);
    transition:0.5s;
}

.contactMe_Text:hover
{
    background-color:black;
    color:white;
    transform:scale(0.92);
    border:none;
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0,1.22,.83,.67);
    box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.483);

}

.contactMe_Text:active
{
    background-color:black;
    color:white;
    transform:scale(0.96);
    transition: 0.5s;
    transition-timing-function: cubic-bezier(0,1.22,.83,.67);
}

.iconify.whatsappIcon
{
    transform:scale(1.4);
    padding:0  0.25rem;
}

.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    font-family: 'Prospec';
    /* color: rgb(139, 229, 217); */
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}





/* --------------------------------------------------- */



@media screen and (max-width:1400px) {
    .presentation {
        display:flex;
        justify-content:center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
    }
    .mainTitle
    {
        align-items: center;
    }
}

@media screen and (max-width:800px) {
    
    .contact_me_button
    {
        display:flex;
        padding-top:0.5rem;
    }
    .contactMe_Text
    {
        font-size: 0.9rem;
        padding: 0.6rem;
        border:1.5px solid #000;
        border-radius: 40px;
        text-shadow: 0em 0em 0em;
    }
    .iconify.whatsappIcon
    {
        transform:scale(1.4);
        padding: 0 0.25rem;
    }
    .creativeDevText
    {
        -moz-text-stroke:0.5px black;
        -webkit-text-stroke:0.5px black;
    }
  }


@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
.creativeDevText
{
    text-shadow: 0em 0em 15px rgba(0, 0, 0, 0.512);
}
}