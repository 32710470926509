.pointProjectsLab
{
    font-size: 3vmin;
    color:transparent;
    /* background-image: linear-gradient(0deg, rgb(5, 37, 222) 0%, rgb(252, 117, 102) 56%, rgba(254, 193, 149, 1) 100%); */
    background-image: radial-gradient(circle at center, #2cb7ee 0%, #66fcea 56%, rgb(251, 155, 86) 80%);
    -webkit-background-clip: text;
}

.spanProjectGradientTextTurquoise
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #22767b;
    -moz-text-stroke:0.5px #22767b;
    background-image:url(../../../static/assets/orangeBackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.spanProjectGradientTextBlue
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #0074a2;
    -moz-text-stroke:0.5px #0074a2;
    background-image:url(../../../static/assets/blueBackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.textProjectsTestLab:hover > .pointProjectsLab
{
    color:black;
    -webkit-text-stroke:2px black;
    transition:0.15s;
}

.textProjectsTestLab:hover > .spanProjectGradientTextTurquoise
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #000000;
    -moz-text-stroke:0.5px #000000;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.textProjectsTestLab:hover > .spanProjectGradientTextBlue
{
    color:transparent;
    font-size: 1.5vmin;
    -webkit-background-clip: text;
    -webkit-text-stroke:0.5px #000000;
    -moz-text-stroke:0.5px #000000;
    background-image:url(../../../static/assets/blackbackground.svg);
    -webkit-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-animation: back 15s linear infinite alternate;
    transition:0.15s;
}

.mouseInfo
{
    border-radius:50px;
    font-family: 'Prospec';
    position:fixed;
    /* top:685px;
    left:884px; */
    background: rgba(121, 121, 121, 0.318);
    box-shadow: 0 0px 40px 0 rgba(255, 255, 255, 0.365);
    backdrop-filter: blur( 13px );
    -webkit-backdrop-filter: blur( 13px );

}




/*-------------------------------------------------------*/
.textProjectsTestLab
{
    font-family: 'CharlesElegant';
    text-transform: lowercase;
    font-size:2vmin;
    margin: 0.2rem 0;
    letter-spacing:2.5px;
    transition:0.2s;
    color: #000;
}

.textProjectsTestLab::first-letter
{
    text-transform: uppercase;
}

.colorBoxLab
{
    height:25px;
    width:0px;
    margin-left:10px;
    /* background: linear-gradient(45deg, red, blue); */
    background: linear-gradient(90deg, #2cb7ee 0%, #66fcea  100%);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition:0.3s;
}

.singleline:hover > .colorBoxLab
{    width:100px;
     transition:0.2s;
     animation-timing-function: cubic-bezier(.17,1.34,.9,.59);
}

.movieEditingDiv
{
    /* background-color:#1AB; */
    display: flex;
    flex-direction:row;
    padding-top:3.5%;
}

.block
{
    background-color:#FFF;
    padding:2rem;
    margin:30px 0px 30px 30px;
    border-radius: 30px;
    width: 12rem;
    background: rgba( 255, 255, 255, 0.15 );
    backdrop-filter: blur( 14.5px );
    -webkit-backdrop-filter: blur( 14.5px );
    -moz-filter: blur(14.5px);
    -o-filter: blur(14.5px);
}

.blockText
{
    font-size: 2rem;
    padding-bottom:1rem;
    text-shadow: 0em 0em 0.5em rgba(0, 0, 0, 0.307);
}

.technologyUsedDiv
{
    background-color:#FFF;
    padding:2rem;
    width:calc(24rem + 30px + 2rem + 2rem);
    border-radius: 30px;
    background: rgba( 255, 255, 255, 0.15 );
    backdrop-filter: blur( 14.5px );
    -webkit-backdrop-filter: blur( 14.5px );
    -moz-filter: blur(14.5px);
    -o-filter: blur(14.5px);
}
/*---------------*/


@media screen and (min-width:800px) and (max-width:1400px)
{
    .block
{

    width: 11rem;
}

.blockText
{
    font-size: 1.5rem;
    padding-bottom:1rem;
    text-shadow: 0em 0em 0.5em rgba(0, 0, 0, 0.307);
}

.technologyUsedDiv
{
    background-color:#FFF;
    padding:2rem;
    width:calc(22rem + 30px + 2rem + 2rem);
    border-radius: 30px;
    background: rgba( 255, 255, 255, 0.15 );
    backdrop-filter: blur( 14.5px );
    -webkit-backdrop-filter: blur( 14.5px );
    -moz-filter: blur(14.5px);
    -o-filter: blur(14.5px);
}
}


@media  screen and (max-width:800px)
{
    .textProjectsTestLab
    {
        font-family: 'CharlesElegant';
        text-transform: lowercase;
        font-size:3.4vmin;
        margin: 0.5rem 0;
        letter-spacing:2.5px;
        transition:0.2s;
        color:#000;
    }
    .spanProjectGradientTextTurquoise
    {
        font-size: 2.9vmin;
    }
    .spanProjectGradientTextBlue
    {
        font-size: 2.5vmin;
    }
    .pointProjectsLab
    {
        font-size: 5vmin;
    }
/*-----------------------------------------------------------------*/
    .movieEditingDiv
    {
        /* background-color:#1AB; */
        display: flex;
        flex-direction:row;
        padding-top:3.5%;
    }

    .block
    {
        padding:0.5rem 0rem 0.5rem 0.7rem;
        margin:10px 10px 20px 10px;
        border-radius: 20px;
        width:38vw;
        background: rgba( 255, 255, 255, 0.15 );
        backdrop-filter: blur( 14.5px );
        -webkit-backdrop-filter: blur( 14.5px );
        -moz-filter: blur(14.5px);
        -o-filter: blur(14.5px);
    }

    .blockText
    {
        font-size: 1rem;
        padding-bottom:0.2rem;
        text-shadow: 0em 0em 0.5em rgba(0, 0, 0, 0.307);
    }

    .technologyUsedDiv
    {
        background-color:#FFF;
        padding: 1rem 0.7rem 0.5rem 0.7rem;
        border-radius: 20px;
        width: calc(76vw + 20px );
        background: rgba( 255, 255, 255, 0.15 );
        backdrop-filter: blur( 14.5px );
        -webkit-backdrop-filter: blur( 14.5px );
        -moz-filter: blur(14.5px);
        -o-filter: blur(14.5px);
    }
}

