.preloadingPageDiv
{
    z-index: 5;
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#fbfbfb;
    /* display:none; */
    /* background-image: linear-gradient(-120deg, #CCE8EA 0%, #FFD9EC 65%, #CCE8EA  100%); */

}

.preloadingPageDiv.hidden 
{
    animation: fadeout 2.6s;
    animation-timing-function: cubic-bezier(0.86, 0, 0.3, 1);
    animation-fill-mode: forwards;
}


@keyframes fadeout
{
    100%
    {
        opacity: 0;
        transform: scale(1.3);
        visibility: hidden;
    }
} 
